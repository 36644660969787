// Event identifiers used in triggers in Google Tag Manager.
// Changing these requires an update in GTM.
const GTM_COPY_API_KEY = 'copy-api-key';
const GTM_VIEW_SDK_BUTTON = 'view-sdk-button';
const GTM_FIRST_DASHBOARD_VISIT = 'first-dashboard-visit';
const GTM_FIRST_APP_DASHBOARD_VISIT = 'first-app-dashboard-visit';
const GTM_TRY_IN_BROWSER_BUTTON = 'try-in-browser-button';
const GTM_VIEW_FIRST_MESSAGE_POPUP = 'view-first-message-popup';
const GTM_CLICK_DEV_CONSOLE_TAB = 'click-dev-console-tab';
const GTM_CLEAR_DEV_CONSOLE_EVENTS = 'clear-dev-console-events';
const GTM_CONNECT_DEV_CONSOLE_EVENTS = 'connect-dev-console-events';
const GTM_DISCONNECT_DEV_CONSOLE_EVENTS = 'disconnect-dev-console-events';
const GTM_PAUSE_DEV_CONSOLE_EVENTS = 'pause-dev-console-events';
const GTM_RESUME_DEV_CONSOLE_EVENTS = 'resume-dev-console-events';
const GTM_ATTACH_TO_DEV_CONSOLE_CHANNEL = 'attach-to-dev-console-channel';
const GTM_DETACH_FROM_DEV_CONSOLE_CHANNEL = 'detach-from-dev-console-channel';
const GTM_PUBLISH_MESSAGE_IN_DEV_CONSOLE_CHANNEL = 'publish-message-in-dev-console-channel';
const GTM_ENTER_PRESENCE_IN_DEV_CONSOLE_CHANNEL = 'enter-presence-in-dev-console-channel';
const GTM_LEAVE_PRESENCE_IN_DEV_CONSOLE_CHANNEL = 'leave-presence-in-dev-console-channel';
const GTM_UPDATE_PRESENCE_IN_DEV_CONSOLE_CHANNEL = 'update-presence-in-dev-console-channel';
const GTM_COPY_CODE_BLOCK_CONTENT = 'copy-code-block-content';
const GTM_CLICK_UPDATE_PACKAGE = 'click-update-package';
const GTM_CLICK_CONFIRM_AND_PAY = 'click-confirm-pay';
const GTM_SUBMIT_CARD_PAYMENT = 'submit-card-payment';
const GTM_CLICK_SIGNUP_BUTTON = 'click-signup-button';
const GTM_CLICK_USE_CASE_BUTTON = 'click-use-case-button';
const GTM_SIGNUP_VALIDATION_FAILED = 'signup-validation-failed';
const GTM_CLICK_PAID_SIGN_UP_BUTTON = 'click-paid-sign-up-button';
const GTM_CLICK_CONFIRM_AND_PAY_BUTTON = 'click-confirm-and-pay-button';
const GTM_CLICK_CONFIRM_PAYMENT_BUTTON = 'click-confirm-payment-button';
const GTM_CLICK_LOGO_ON_PRIMARY_NAV = 'click-logo-on-primary-nav';
const GTM_VIEW_ALL_ACCOUNTS = 'click-view-all-accounts';
const GTM_VIEW_ALL_APPS = 'click-view-all-apps';
const GTM_SWITCH_ACCOUNTS = 'switch-accounts';
const GTM_SWITCH_APPS = 'switch-apps';
const GTM_CLICK_ACCOUNT_BREADCRUMB = 'click-account-breadcrumb';
const GTM_CLICK_APP_BREADCRUMB = 'click-app-breadcrumb';
const GTM_CLICK_MOBILE_NAV = 'click-mobile-nav';
const GTM_CLICK_SUPPORT_LINK = 'click-support-link';
const GTM_CLICK_DEV_DOCS = 'click-dev-docs';
const GTM_CLICK_QUICKSTART_GUIDE = 'click-quickstart-guide';
const GTM_CLICK_SDKS = 'click-sdks';
const GTM_CLICK_TUTORIALS = 'click-tutorials';
const GTM_CLICK_CHANGELOG = 'click-changelog';
const GTM_CLICK_PACKAGE_AND_LIMITS = 'click-package-and-limits';
const GTM_CLICK_ACCOUNT_USAGE = 'click-account-usage';
const GTM_CLICK_BILLING_AND_PACKAGES = 'click-billing-and-packages';
const GTM_CLICK_ACCOUNT_SETTINGS = 'click-account-settings';
const GTM_CLICK_MY_SETTINGS = 'click-my-settings';
const GTM_CLICK_MANAGE_USERS = 'click-manage-users';
const GTM_CLICK_MY_ACCESS_TOKENS = 'click-my-access-tokens';
const GTM_CLICK_LOGOUT = 'click-logout';
const GTM_FIRST_APP_STATS_PAGE_VISIT = 'first-app-stats-page-visit';
const GTM_GETTING_STARTED_CONFETTI_EVENT = 'getting-started-message';
const GTM_CLICK_ACCOUNT_BANNER = 'click-account-banner';
const GTM_CLICK_GETTING_STARTED_EVENT = 'click-dashboard-getting-started-link';

const push = data => {
  window.dataLayer = window.dataLayer || [];
  window.dataLayer.push(data);
};

const copyApiKeyTracker = ({ apiKeyInPageLocation }) => {
  push({ event: GTM_COPY_API_KEY, apiKeyInPageLocation });
};

const viewSdkTracker = ({ sdkLanguage }) => {
  push({ event: GTM_VIEW_SDK_BUTTON, sdkLanguage });
};

const firstDashboardVisitTracker = () => {
  push({ event: GTM_FIRST_DASHBOARD_VISIT });
};

const firstAppDashboardVisitTracker = () => {
  push({ event: GTM_FIRST_APP_DASHBOARD_VISIT });
};

const tryInBrowserTracker = () => {
  push({ event: GTM_TRY_IN_BROWSER_BUTTON });
};

const viewFirstMessagePopupTracker = () => {
  push({ event: GTM_VIEW_FIRST_MESSAGE_POPUP });
};

const clickSignUpButtonTracker = ({ signupProvider }) => {
  push({ event: GTM_CLICK_SIGNUP_BUTTON, signupProvider });
};

const clickUseCaseButtonTracker = () => {
  push({ event: GTM_CLICK_USE_CASE_BUTTON });
};

const signUpValidationFailedTracker = () => {
  push({ event: GTM_SIGNUP_VALIDATION_FAILED });
};

const devConsoleTrackers = {
  clickDevConsoleTab: () => {
    push({ event: GTM_CLICK_DEV_CONSOLE_TAB });
  },
  clearDevConsoleEvents: () => {
    push({ event: GTM_CLEAR_DEV_CONSOLE_EVENTS });
  },

  connectDevConsoleEvents: () => {
    push({ event: GTM_CONNECT_DEV_CONSOLE_EVENTS });
  },

  disconnectDevConsoleEvents: () => {
    push({ event: GTM_DISCONNECT_DEV_CONSOLE_EVENTS });
  },

  pauseDevConsoleEvents: () => {
    push({ event: GTM_PAUSE_DEV_CONSOLE_EVENTS });
  },

  resumeDevConsoleEvents: () => {
    push({ event: GTM_RESUME_DEV_CONSOLE_EVENTS });
  },

  attachToDevConsoleChannel: () => {
    push({ event: GTM_ATTACH_TO_DEV_CONSOLE_CHANNEL });
  },

  detachFromDevConsoleChannel: () => {
    push({ event: GTM_DETACH_FROM_DEV_CONSOLE_CHANNEL });
  },

  publishMessageInDevConsoleChannel: () => {
    push({ event: GTM_PUBLISH_MESSAGE_IN_DEV_CONSOLE_CHANNEL });
  },

  enterPresenceInDevConsoleChannel: () => {
    push({ event: GTM_ENTER_PRESENCE_IN_DEV_CONSOLE_CHANNEL });
  },

  leavePresenceInDevConsoleChannel: () => {
    push({ event: GTM_LEAVE_PRESENCE_IN_DEV_CONSOLE_CHANNEL });
  },

  updatePresenceInDevConsoleChannel: () => {
    push({ event: GTM_UPDATE_PRESENCE_IN_DEV_CONSOLE_CHANNEL });
  },
};

const copyCodeBlockContentTracker = ({ copyCodeBlockContent }) => {
  push({ event: GTM_COPY_CODE_BLOCK_CONTENT, copyCodeBlockContent });
};

const clickUpdatePackageButton = () => {
  push({ event: GTM_CLICK_UPDATE_PACKAGE });
};

const clickConfirmAndPayButton = () => {
  push({ event: GTM_CLICK_CONFIRM_AND_PAY });
};

const submitCardPayment = () => {
  push({ event: GTM_SUBMIT_CARD_PAYMENT });
};

const clickPaidSignUpButtonTracker = () => {
  push({ event: GTM_CLICK_PAID_SIGN_UP_BUTTON });
};

const clickConfirmAndPayButtonTracker = () => {
  push({ event: GTM_CLICK_CONFIRM_AND_PAY_BUTTON });
};

const clickConfirmPaymentButtonTracker = () => {
  push({ event: GTM_CLICK_CONFIRM_PAYMENT_BUTTON });
};

const clickLogoOnPrimaryNav = () => {
  push({ event: GTM_CLICK_LOGO_ON_PRIMARY_NAV });
};

const clickViewAllAccounts = () => {
  push({ event: GTM_VIEW_ALL_ACCOUNTS });
};

const clickViewAllApps = () => {
  push({ event: GTM_VIEW_ALL_APPS });
};

const switchAccounts = () => {
  push({ event: GTM_SWITCH_ACCOUNTS });
};

const switchApps = () => {
  push({ event: GTM_SWITCH_APPS });
};

const clickAccountBreadcrumb = () => {
  push({ event: GTM_CLICK_ACCOUNT_BREADCRUMB });
};

const clickAppBreadcrumb = () => {
  push({ event: GTM_CLICK_APP_BREADCRUMB });
};

const clickMobileNav = () => {
  push({ event: GTM_CLICK_MOBILE_NAV });
};

const clickSupportLink = () => {
  push({ event: GTM_CLICK_SUPPORT_LINK });
};

const clickDevDocs = () => {
  push({ event: GTM_CLICK_DEV_DOCS });
};

const clickQuickStartGuide = () => {
  push({ event: GTM_CLICK_QUICKSTART_GUIDE });
};

const clickSdks = () => {
  push({ event: GTM_CLICK_SDKS });
};

const clickTutorials = () => {
  push({ event: GTM_CLICK_TUTORIALS });
};

const clickChangelog = () => {
  push({ event: GTM_CLICK_CHANGELOG });
};

const clickPackageAndLimits = () => {
  push({ event: GTM_CLICK_PACKAGE_AND_LIMITS });
};

const clickAccountUsage = () => {
  push({ event: GTM_CLICK_ACCOUNT_USAGE });
};

const clickBillingAndPackages = () => {
  push({ event: GTM_CLICK_BILLING_AND_PACKAGES });
};

const clickAccountSettings = () => {
  push({ event: GTM_CLICK_ACCOUNT_SETTINGS });
};

const clickManageUsers = () => {
  push({ event: GTM_CLICK_MANAGE_USERS });
};

const clickMySettings = () => {
  push({ event: GTM_CLICK_MY_SETTINGS });
};

const clickMyAccessTokens = () => {
  push({ event: GTM_CLICK_MY_ACCESS_TOKENS });
};

const clickLogout = () => {
  push({ event: GTM_CLICK_LOGOUT });
};

const clickAppNavigationTab = tabName => {
  const transformedTabName = tabName
    .toLowerCase()
    .split(' ')
    .join('-');

  push({ event: `click-app-navigation-${transformedTabName}-tab` });
};

const firstAppStatsPageVisitEvent = () => {
  push({ event: GTM_FIRST_APP_STATS_PAGE_VISIT });
};

const triggerConfettiMessageEvent = () => {
  push({ event: GTM_GETTING_STARTED_CONFETTI_EVENT });
};

const clickAccountBanner = () => {
  push({ event: GTM_CLICK_ACCOUNT_BANNER });
};

const clickGettingStartedEvent = ({ linkName }) => {
  push({ event: GTM_CLICK_GETTING_STARTED_EVENT, linkName });
};

export {
  copyApiKeyTracker,
  viewSdkTracker,
  firstDashboardVisitTracker,
  firstAppDashboardVisitTracker,
  tryInBrowserTracker,
  viewFirstMessagePopupTracker,
  devConsoleTrackers,
  copyCodeBlockContentTracker,
  clickUpdatePackageButton,
  clickConfirmAndPayButton,
  submitCardPayment,
  clickSignUpButtonTracker,
  clickUseCaseButtonTracker,
  signUpValidationFailedTracker,
  clickPaidSignUpButtonTracker,
  clickConfirmAndPayButtonTracker,
  clickConfirmPaymentButtonTracker,
  clickLogoOnPrimaryNav,
  clickViewAllAccounts,
  clickViewAllApps,
  switchAccounts,
  switchApps,
  clickAccountBreadcrumb,
  clickAppBreadcrumb,
  clickMobileNav,
  clickSupportLink,
  clickDevDocs,
  clickQuickStartGuide,
  clickSdks,
  clickTutorials,
  clickChangelog,
  clickPackageAndLimits,
  clickAccountUsage,
  clickBillingAndPackages,
  clickAccountSettings,
  clickManageUsers,
  clickMySettings,
  clickMyAccessTokens,
  clickLogout,
  clickAppNavigationTab,
  firstAppStatsPageVisitEvent,
  triggerConfettiMessageEvent,
  clickAccountBanner,
  clickGettingStartedEvent,
};
