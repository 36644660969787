// This file is loaded both on legacy and new layouts and should be moved into application-next
// when we don't have the old layouts anymore (or we don't need the hybrid layouts with AblyUi on legacy pages.)

import './ably-ui.css';

import sprites from '@ably/ui/core/sprites.svg';
import {
  loadSprites,
  createRemoteDataStore,
  reducerBlogPosts,
  reducerSessionData,
  attachStoreToWindow,
  fetchBlogPosts,
  fetchSessionData,
  queryId,
} from '@ably/ui/core/scripts';

import renderer from 'global/react/renderer';

import Meganav from '@ably/ui/core/Meganav/component';
import MeganavBlogPostsList from '@ably/ui/core/MeganavBlogPostsList/component';
import Notice from '@ably/ui/core/Notice/component';
import CookieMessage from '@ably/ui/core/CookieMessage';
import Flash, { reducerFlashes } from '@ably/ui/core/Flash';
import FeatureFooter from '../components/feature-footer/component';

import Status from '../components/react/status';

const store = createRemoteDataStore({ ...reducerBlogPosts, ...reducerSessionData, ...reducerFlashes });
attachStoreToWindow(store);

document.addEventListener('DOMContentLoaded', () => {
  // Inserts a sprite map for <use> tags
  loadSprites(sprites);

  // Init ViewComponent components
  const meganav = queryId('meganav');
  const featureFooter = queryId('feature-footer');

  const { addSearchApiKey } = document.body.dataset;

  if (meganav) {
    Meganav({ addSearchApiKey });
    MeganavBlogPostsList();
  }

  if (featureFooter) {
    FeatureFooter();
  }

  // Init React components
  renderer({ Flash, CookieMessage, Status });

  const blogPostsUrl = document.body.dataset.apiBlogPosts;
  const sessionDataUrl = document.body.dataset.apiSessionData;

  const { noticeCookieId: cookieId, noticeId, noticeConfigCollapse } = document.body.dataset;

  // Fetch additional data, trigger a re-render for components subscribed to store
  if (blogPostsUrl) {
    fetchBlogPosts(store, blogPostsUrl);
  }

  // Only fetch on layouts that use session data
  if (sessionDataUrl) {
    fetchSessionData(store, sessionDataUrl);
  }

  const bannerContainer = queryId('ui-notice');

  if (bannerContainer && noticeId) {
    Notice({
      bannerContainer,
      cookieId,
      noticeId,
      options: {
        collapse: noticeConfigCollapse === 'true',
      },
    });
  }
});
