/* eslint-disable func-names */
/* global grecaptcha, msgboxerror */
// Render recaptcha
$(function () {
  if (window.grecaptcha && $('#user_recaptcha').is(':empty')) {
    grecaptcha.ready(function () {
      return grecaptcha.render('user_recaptcha', {
        sitekey: $('#user_recaptcha').data('recaptchakey'),
      });
    });
  }
});

$(document)
  .on('ajax:error', '#new_user', function (_evt, xhr) {
    try {
      // eslint-disable-next-line camelcase
      const { header_errors, errors } = JSON.parse(xhr.responseText);

      $('#new_session_user_email').val($('#user_email').val());
      $('#new_session_user_password').trigger('focus');

      // eslint-disable-next-line camelcase
      const errmsg = header_errors.join('<br>');

      $('#new-user-msgbox').flashMessages({
        message: errmsg,
        template: msgboxerror,
      });

      const formErrors = errors && errors.user;

      $('.error-message', this).remove();
      $('.error', this).removeClass('error');
      $.each(formErrors, function (attr, values) {
        if (values[0] != null) {
          const $element = $(`#user_${attr}`);
          $element.addClass('error');
          if ($element.next('.error-message').length === 0) {
            $element.after(`<span class="error-message">${values[0]}</span>`);
          }
        }
      });

      // Reset recaptcha after request
      if (window.grecaptcha && $('#user_recaptcha').is(':visible')) {
        grecaptcha.reset($('#user_recaptcha').attr('captcha-id'));
      }
    } catch (e) {
      $('#new-user-msgbox').flashMessages({
        message:
          "Oops, something went wrong on our side. Please try again or <a href='/contact'>contact us</a> for help",
        template: msgboxerror,
      });
    }
  })
  .on('ajax:success', '#new_user', function () {
    // delegate redirect to ajax:complete
    $(this).data('redirect', true);
  })
  .on('ajax:success', '#edit_user, #edit_user_email_preferences', function () {
    $('[data-js="user-profile-err-box"]').html('');
    $('[data-main]').css('margin-top', '0px');
    Alert.success('Your account was updated successfully');
  })
  .on('ajax:error', '#edit_user', function (_event, data) {
    $('[data-js-header-alerts-container]').empty();
    let errContent = '';
    let errors = {};

    try {
      ({ errors } = JSON.parse(data.responseText));
      errContent = 'We could not update your personal settings. Please correct the following:';
    } catch (e) {
      errContent =
        "Oops, something went wrong on our side. Please try again or <a href='/contact'>contact us</a> for help";
    }

    $('[data-flash-editUser] [data-js="user-profile-err-box"]').flashMessages({
      message: errContent,
      template: msgboxerror,
      errors: Object.values(errors),
    });
    const heightMsgContent = $('[data-js="user-profile-err-box"]').outerHeight(true);
    $('[data-main]').css('margin-top', heightMsgContent);
  })
  .on('ajax:error', '#edit_password', function (_event, data) {
    let errContent = '';
    let errors = {};

    try {
      ({ errors } = JSON.parse(data.responseText));
      const actionTxt = $('#edit_password').hasClass('create-pw-form') ? ' create ' : ' update ';

      errContent = `We could not${actionTxt}your password. Please correct the following:`;
    } catch (e) {
      errContent =
        "Oops, something went wrong on our side. Please try again or <a href='/contact'>contact us</a> for help";
    }

    $('#password-box [data-dialog-msgbox]').flashMessages({
      message: errContent,
      template: msgboxerror,
      errors: Object.values(errors),
    });
  })
  .on('ajax:success', '#edit_password', function () {
    $(this).data('redirect', true);
  })
  .on('click', 'a.oauth-disconnect-link', function () {
    return false;
  });
