window.addEventListener(
  'message',
  (event) => {
    if (event.data.iframeHeight) {
      const iframe = document.getElementById('uptime_iframe');
      const loader = document.querySelector('.js-iframe-resizable-loader');

      iframe.style.height = `${event.data.iframeHeight}px`;

      if (loader) {
        loader.style.display = 'none';
      }
    }
  },
  false,
);
