/**
 * Newsletter poppers components
 */
import './newsletter.scss';
import importAll from 'global/utils/import-all';

const context = require.context('@javascripts/templates/newsletter', true, /\.hbs$/);

const newsletterTemplates = importAll(context, { namespace: 'newsletter/' });

/**
 * All this code bellow is hack to keep modules/newletter.js.coffee
 * backwards(Sprockets) compatible
 * @note [1] - namespace object keys in a handlebars_assets gem format
 * @note [2] -  handlebars_assets exposes both Handlebars and HandlebarsTemplates
 * as window global variables :S
 * @todo [3] - Cleanup globals, Expose global variable used inside
 * modules/newsletter, Mimics the behaviour of the gem, remove it after
 */
window.HandlebarsTemplates = {
  ...window.HandlebarsTemplates,
  ...newsletterTemplates,
};

// require because order is important
// eslint-disable-next-line import/first
import '@javascripts/modules/newsletter.js.coffee';
