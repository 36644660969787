const accordionTitles = document.getElementsByClassName('accordion-item');

Array.from(accordionTitles).forEach(accordionTitleEl => {
  accordionTitleEl.addEventListener('click', event => {
    const accordionId = event.currentTarget.getAttribute('data-accordion-id');

    const content = document.querySelector(`[data-accordion-content=${accordionId}`);
    const isClosed = !content.style.height || content.style.height === '0px';

    const plusIcon = document.querySelector(`[data-accordion-id=${accordionId}] .js-accordion-plus-icon`);
    const minusIcon = document.querySelector(`[data-accordion-id=${accordionId}] .js-accordion-minus-icon`);

    if (isClosed) {
      content.style.height = `${content.scrollHeight}px`;
    } else {
      content.style.height = 0;
    }

    plusIcon.classList.toggle('hidden');
    minusIcon.classList.toggle('hidden');
  });
});
