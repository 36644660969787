import { clickSignUpButtonTracker, signUpValidationFailedTracker } from 'util/analytics/gtm';

export default function getSingupValidationTrackers() {
  let signupValidationError = false;
  let hasSentSignupError = false;

  const form = document.querySelector('#new_user');
  if (form !== null) {
    /**
     * Here we are setting up an observer to check for DOM changes.
     * If the DOM changes and we find the `.new-user-msgbox` element,
     * we know the validation has failed and we can sent a GTM event.
     */
    const observer = new MutationObserver(nodeList => {
      const errorMessageContainer = nodeList.find(element => element.target.id === 'new-user-msgbox');
      if (hasSentSignupError) return;
      if (errorMessageContainer) signupValidationError = true;
      if (signupValidationError) {
        hasSentSignupError = true;
        signUpValidationFailedTracker();
      }
    });

    const observerOptions = {
      childList: true,
      subtree: true,
    };

    observer.observe(form, observerOptions);
  }

  const clickHandler = event => {
    const stringValueList = event.target.innerText ? event.target.innerText.split(' ') : event.target.value.split(' ');

    const providerRaw = stringValueList[stringValueList.length - 1];
    const provider = providerRaw === 'account' ? 'email-and-password' : providerRaw.toLowerCase();
    clickSignUpButtonTracker({ signupProvider: provider });
  };

  const emailSignupButton = document.querySelector('#signup-button');
  if (emailSignupButton !== null) {
    emailSignupButton.addEventListener('click', clickHandler);
  }

  const authButtons = document.querySelectorAll('.c-signup__oauth-links a');
  authButtons.forEach(buttonEl => {
    buttonEl.addEventListener('click', clickHandler);
  });
}
