import "./media-poster.scss";
import "global/plugins/fancybox/fancybox";

/**
 * @desc an image wrapped in a link thatopens a fancybox dialog with the
 * embed media url
 */
document.addEventListener(
  "DOMContentLoaded",
  function attachEventsToMediaPosters() {
    $('[data-js-component="media-poster-link"]')
      .data("fancybox-type", "iframe")
      .fancybox({
        openEffect: "none",
        closeEffect: "none",
        width: "80%",
        height: "60%",
        autoSize: true,
        scrolling: "no",
        arrows: false,
        iframe: {
          preload: false
        }
      });
  }
);
