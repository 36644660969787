import { setupContinueWithEmail } from 'util/loginAndSignupForms';
import getSignUpTrackers from 'util/getSignupTrackers';
import CompanyAutocomplete from '../company-autocomplete/component';

import './signup.scss';

// signup terms component
import './banner/banner';
import './terms/terms';

document.addEventListener('DOMContentLoaded', () => {
  setupContinueWithEmail('new_user');

  const { apiCompanyAutocompleteUri } = document.body.dataset;
  const input = document.querySelectorAll('#user_company_name')[0];
  CompanyAutocomplete(input, apiCompanyAutocompleteUri);

  getSignUpTrackers();
});
