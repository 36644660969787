/**
 * Imports and maps globs of files using require.context
 *
 * @link https://webpack.js.org/guides/dependency-management/#require-context
 *
 * @param {Function} r resolve function exported require.context
 * @param {Boolean} replaceLeadSlash - to replace file path leading slash
 * @param {Boolean} replaceExtension - to replace file path leading slash
 * @returns {Object} key value map with parsed key + imported file value (contents depends on loader)
 */

export default function importAll(webpackContext, config = {}) {
  const DEFAULTS = {
    namespace: "",
    replaceLeadSlash: true,
    replaceExtension: true
  };

  const options = { ...DEFAULTS, ...config };
  const map = {};

  webpackContext.keys().forEach(key => {
    let k = key;

    if (options.replaceLeadSlash) {
      k = key.replace("./", "");
    }

    if (options.replaceExtension) {
      k = k.substring(k.lastIndexOf("."), -1);
    }

    k = options.namespace + k;

    return (map[k] = webpackContext(key));
  });

  return map;
}
